import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import './Contact.css';

const Contact = () => {
    return (
        <Box className="contact-container">
            <Container maxWidth="md">
                <Typography variant="h2" className="contact-title">
                    Contact Us
                </Typography>
                <Typography variant="h5" className="contact-message">
                    Please send an email to{' '}
                    <Link href="mailto:amr@elevaide.com" className="contact-email">
                        amr@elevaide.com
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
};

export default Contact;