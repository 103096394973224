import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import config from '../../../config';
import './ChatWithAI.css';

const ChatWithAI = ({transcriptData}) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [showMessage, setShowMessage] = useState({show: false, type: '', message: ''});
    const [isLoading, setIsLoading] = useState(true);
    const [isResponding, setIsResponding] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [currentConversationId, setCurrentConversationId] = useState(null);
    const [sidebarState, setSidebarState] = useState('hidden');
    const [anchorEl, setAnchorEl] = useState(null);
    const chatContainerRef = useRef(null);
    const sidebarRef = useRef(null);
    const chatWithAIRef = useRef(null);

    const {transcript = {utterances: [], speaker_names: []}, metaData = {}} = transcriptData;

    useEffect(() => {
        if (metaData.meetingId && metaData.transcriptId) {
            fetchConversations();
        }
    }, [metaData.meetingId, metaData.transcriptId]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (chatWithAIRef.current) {
                const rect = chatWithAIRef.current.getBoundingClientRect();
                const isNearRightEdge = (rect.right - event.clientX) <= 0;

                if (isNearRightEdge && sidebarState === 'hidden') {
                    setSidebarState('visible');
                } else if (!isNearRightEdge && sidebarState === 'visible') {
                    const isFarFromSidebar = (rect.right - event.clientX) > 270;
                    if (isFarFromSidebar) {
                        setSidebarState('hidden');
                    }
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [sidebarState]);

    const fetchConversations = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/meeting-chat/list_chats/`, {
                params: {
                    meetingId: metaData.meetingId,
                    transcriptId: metaData.transcriptId
                }
            });
            setConversations(response.data.chats);
            if (response.data.currentChat) {
                setCurrentConversationId(response.data.currentChat.id);
                setChatMessages(response.data.currentChat.messages);
            } else {
                console.error('Error fetching conversations:');
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error fetching conversations. Please try again.'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChatHistory = async (chatId) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/meeting-chat/get_chat_history/`, {
                params: {
                    meetingId: metaData.meetingId,
                    transcriptId: metaData.transcriptId,
                    chatId: chatId
                }
            });
            if (response.data.currentChat) {
                setCurrentConversationId(response.data.currentChat.id);
                setChatMessages(response.data.currentChat.messages);
            }
        } catch (error) {
            console.error('Error fetching chat history:', error);
            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error fetching chat history. Please try again.'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessage = async () => {
        if (!userInput.trim() || !currentConversationId) return;

        try {
            setIsResponding(true);
            const response = await axios.post(`${config.backendUrlApiV1}/meeting-chat/send_message/`, {
                message: userInput,
                meetingId: metaData.meetingId,
                transcriptId: metaData.transcriptId,
                chatId: currentConversationId
            });

            setChatMessages(response.data.messages);
            setUserInput('');

            // Update the isEmpty status of the current conversation
            setConversations(prevConversations =>
                prevConversations.map(chat =>
                    chat.id === currentConversationId
                        ? {...chat, isEmpty: false}
                        : chat
                )
            );

        } catch (error) {
            console.error('Error sending message to chatbot:', error);
            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error communicating with the chatbot. Please try again.'
            });
        } finally {
            setIsResponding(false);
        }
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowMessage({...showMessage, show: false});
    };

    const handleConversationClick = (conversationId) => {
        if (currentConversationId === conversationId) return;
        fetchChatHistory(conversationId);
    };

    const handleNewConversation = async () => {
        const emptyChat = conversations.find(chat => chat.isEmpty);
        if (emptyChat) {
            handleConversationClick(emptyChat.id);
            return;
        }

        try {
            const response = await axios.post(`${config.backendUrlApiV1}/meeting-chat/new_chat/`, {
                meetingId: metaData.meetingId,
                transcriptId: metaData.transcriptId
            });

            const newChat = response.data;
            setConversations(prevConversations => [newChat, ...prevConversations]);
            setCurrentConversationId(newChat.id);
            setChatMessages([]);
        } catch (error) {
            console.error('Error creating new chat:', error);
            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error creating new chat. Please try again.'
            });
        }
    };

    const handleMenuOpen = (event, conversationId) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (isLoading) {
        return <Typography>Loading chat history...</Typography>;
    }

    return (
        <Box className="chat-with-ai-container" ref={chatWithAIRef}>
            <Box className="chat-content">
                <Box className="chat-messages-wrapper">
                    <Paper elevation={3} className="chat-messages" ref={chatContainerRef}>
                        {chatMessages.map((message, index) => (
                            <Box key={index} className={`message ${message.role}`}>
                                {message.role === 'assistant' ? (
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                ) : (
                                    <Typography variant="body1">{message.content}</Typography>
                                )}
                            </Box>
                        ))}
                        {isResponding && (
                            <Box className="message assistant loading">
                                <CircularProgress size={20}/>
                                <Typography variant="body1">Generating response...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Box>
                <Box className="chat-input-wrapper">
                    <Box className="chat-input">
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Type your message here..."
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && !isResponding && handleSendMessage()}
                            disabled={isResponding}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSendMessage}
                            className="send-button"
                            disabled={isResponding}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box
                ref={sidebarRef}
                className={`conversation-sidebar ${sidebarState}`}
            >
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={handleNewConversation}
                    className="new-conversation-button"
                >
                    New Chat
                </Button>
                {conversations.map((conversation) => (
                    <Box
                        key={conversation.id}
                        className={`conversation-item ${currentConversationId === conversation.id ? 'selected' : ''} ${conversation.isEmpty ? 'empty' : ''}`}
                        onClick={() => handleConversationClick(conversation.id)}
                    >
                        <Typography>{conversation.title}</Typography>
                        {/*<IconButton*/}
                        {/*    size="small"*/}
                        {/*    onClick={(event) => handleMenuOpen(event, conversation.id)}*/}
                        {/*>*/}
                        {/*    <MoreVertIcon/>*/}
                        {/*</IconButton>*/}
                    </Box>
                ))}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMenuClose}>Rename</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
                </Menu>
            </Box>
            <Snackbar
                open={showMessage.show}
                autoHideDuration={6000}
                onClose={handleCloseMessage}
            >
                <Alert onClose={handleCloseMessage} severity={showMessage.type} sx={{width: '100%'}}>
                    {showMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ChatWithAI;