// src/components/Feedback.js
import React, {useState} from 'react';
import {Box, Typography, TextField, Button} from '@mui/material';
import './Feedback.css';
import axios from "axios";
import config from "../../config";

const Feedback = ({open, onClose}) => {
    const [title, setTitle] = useState('');
    const [feedback, setFeedback] = useState('');

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        const postData = {
            title: title,
            feedback: feedback // Assuming feedback corresponds to feedback
        };
        try {
            await axios.post(`${config.backendUrlApiV1}/submit-feedback/`, postData);
        } catch (error) {
            console.error('Error Sending Feedback:', error);
        }
        onClose();
    };

    return (
        <div className={`modal-overlay ${open ? 'fade-in' : ''}`} onClick={onClose}>
            <Box className="modal-box" onClick={(e) => e.stopPropagation()}>
                <Typography variant="h5" className="modal-title">Give Feedback</Typography>
                <TextField
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="feedback"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    fullWidth
                    multiline
                    rows={7}
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    className="save-button"
                    onClick={handleSubmitFeedback}
                >
                    Submit Feedback
                </Button>
            </Box>
        </div>
    );
};

export default Feedback;
