import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import './Terms.css';

const Terms = () => {
    return (
        <Box className="terms-container">
            <Container maxWidth="md">
                <Typography variant="h2" className="terms-title">
                    Terms and Conditions
                </Typography>
                <Typography variant="h4" className="terms-message">
                    Terms and conditions are being written at the moment
                </Typography>
            </Container>
        </Box>
    );
};

export default Terms;