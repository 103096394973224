// src/auth/AuthCallback.js
import React, {useEffect} from 'react';
import {useAuth} from "./AuthProvider";
import {useNavigate} from "react-router-dom";

const AuthCallback = () => {
    const {handleLogin} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const email = params.get('email');
        const provider = params.get('provider');
        const savedUser = localStorage.getItem('user');

        if (!savedUser && token && email && provider) {
            handleLogin(token, email, provider);
            navigate('/product');
        } else if (savedUser) {
            navigate('/product');
        } else {
            navigate('/sign-in');
        }
    }, [navigate, handleLogin]);

    return <div>Loading...</div>;
};

export default AuthCallback;