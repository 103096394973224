import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, IconButton, Typography, Divider,
    List, ListItem, ListItemIcon, ListItemText, Collapse, Tooltip, useTheme, Box, Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RecommendationIcon from '@mui/icons-material/Lightbulb';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import SchoolIcon from '@mui/icons-material/School';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoItem from './InfoItem';

const SectionTitle = ({ icon, children }) => (
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon}
        <span style={{ marginLeft: '8px' }}>{children}</span>
    </Typography>
);

const formatTime = (milliseconds) => {
    if (!milliseconds) return 'N/A';
    const seconds = milliseconds / 1000;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

const SignificantEventInfoCard = ({ event, open, onClose, onJumpToEvent }) => {
    const [recommendationExpanded, setRecommendationExpanded] = useState(false);
    const [relevantTheoryExpanded, setRelevantTheoryExpanded] = useState(false);
    const [sentimentExpanded, setSentimentExpanded] = useState(false);
    const [metricsExpanded, setMetricsExpanded] = useState(false);
    const theme = useTheme();

    const handleExpandClick = (setter) => () => {
        setter(prev => !prev);
    };

    const startTime = formatTime(event.startTime);
    const endTime = formatTime(event.endTime);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            aria-labelledby="significant-event-info-dialog-title"
        >
            <DialogTitle id="significant-event-info-dialog-title" sx={{ m: 0, p: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {event.eventName}
                    </Typography>
                    <Button
                        startIcon={<PlayArrowIcon />}
                        onClick={() => onJumpToEvent(event.startTime)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ mr: 2 }}
                    >
                        Jump to Event
                    </Button>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <InfoItem label="Category" value={event.category} icon={<CategoryIcon color="primary" fontSize="small" />} />
                <InfoItem label="Time Range" value={`${startTime} - ${endTime}`} icon={<AccessTimeIcon color="primary" fontSize="small" />} />

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<SummarizeIcon color="primary" />}>Summary</SectionTitle>
                <Typography variant="body2" paragraph color="text.secondary">
                    {event.analysis || "No summary available."}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<RecommendationIcon color="primary" />}>
                    Recommendation
                    <Tooltip title={recommendationExpanded ? "Hide recommendation" : "Show recommendation"}>
                        <IconButton
                            onClick={handleExpandClick(setRecommendationExpanded)}
                            aria-expanded={recommendationExpanded}
                            aria-label="toggle recommendation"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: recommendationExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={recommendationExpanded} timeout="auto" unmountOnExit>
                    <Typography variant="body2" paragraph color="text.secondary">
                        {event.recommendation || "No recommendation available."}
                    </Typography>
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<SchoolIcon color="primary" />}>
                    Relevant Theory
                    <Tooltip title={relevantTheoryExpanded ? "Hide relevant theory" : "Show relevant theory"}>
                        <IconButton
                            onClick={handleExpandClick(setRelevantTheoryExpanded)}
                            aria-expanded={relevantTheoryExpanded}
                            aria-label="toggle relevant theory"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: relevantTheoryExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={relevantTheoryExpanded} timeout="auto" unmountOnExit>
                    <Typography variant="body2" paragraph color="text.secondary">
                        {event.relevantTheory || "No relevant theory available."}
                    </Typography>
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<SentimentSatisfiedAltIcon color="primary" />}>
                    Sentiment Analysis
                    <Tooltip title={sentimentExpanded ? "Hide sentiment analysis" : "Show sentiment analysis"}>
                        <IconButton
                            onClick={handleExpandClick(setSentimentExpanded)}
                            aria-expanded={sentimentExpanded}
                            aria-label="toggle sentiment analysis"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: sentimentExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={sentimentExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1 }}>
                        <InfoItem label="Emotional Tone" value={event.emotionalTone} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                        <InfoItem label="Emotional Tone Reasoning" value={event.emotionalToneReasoning} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                        <InfoItem label="Sentiment" value={event.sentiment} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                        <InfoItem label="Sentiment Reasoning" value={event.sentimentReasoning} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                    </Box>
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<BarChartIcon color="primary" />}>
                    Metrics
                    <Tooltip title={metricsExpanded ? "Hide metrics" : "Show metrics"}>
                        <IconButton
                            onClick={handleExpandClick(setMetricsExpanded)}
                            aria-expanded={metricsExpanded}
                            aria-label="toggle metrics"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: metricsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={metricsExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1 }}>
                        <InfoItem
                            label="Importance Score"
                            value={event.importanceScore ? event.importanceScore.toFixed(2) : 'N/A'}
                            icon={<StarIcon color="primary" fontSize="small" />}
                        />
                        <InfoItem
                            label="Confidence Score"
                            value={event.confidenceScore ? event.confidenceScore.toFixed(2) : 'N/A'}
                            icon={<VerifiedIcon color="primary" fontSize="small" />}
                        />
                    </Box>
                </Collapse>
            </DialogContent>
        </Dialog>
    );
};

export default SignificantEventInfoCard;
