import React from 'react';
import { Box, Typography } from '@mui/material';

const InfoItem = ({ label, value, icon }) => (
    <Box display="flex" alignItems="center" mb={1}>
        {icon}
        <Typography variant="body2" ml={1}>
            <strong>{label}:</strong> {value ?? 'N/A'}
        </Typography>
    </Box>
);

export default InfoItem;
