import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/ExpandMore';
import './Transcription.css';
import axios from "axios";
import config from "../../config";
import {useRefresh} from "../../auth/RefreshProvider";

const Transcription = forwardRef(({data, setData}, ref) => {
    const containerRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [editBoxIndex, setEditBoxIndex] = useState(null);
    const [optionBoxStyle, setOptionBoxStyle] = useState({});
    const itemRefs = useRef([]);
    const [hoveredName, setHoveredName] = useState(null);
    const [editedName, setEditedName] = useState('');
    const inputRef = useRef(null);
    const { triggerRefresh } = useRefresh();


    const {transcript = {utterances: [], speaker_names: []}, metaData = {}} = data;
    const {utterances = [], speakerNames = []} = transcript;
    const sortedSpeakerNames = [...speakerNames].sort((a, b) => a.localeCompare(b));

    const colors = config.colorsConstants

    const getSpeakerColor = (speakerName) => {
        const index = sortedSpeakerNames.indexOf(speakerName) % colors.length;
        return colors[index];
    };

    const findStatementIndex = (statement, utterances) => {
        for (let i = 0; i < utterances.length; i++) {
            if (utterances[i].text.toLowerCase().includes(statement.toLowerCase())) {
                return i;
            }
        }
        return -1;
    };

    useImperativeHandle(ref, () => ({
        highlightAndScrollTo(statement) {
            const index = findStatementIndex(statement, utterances);
            if (index !== -1 && itemRefs.current[index]) {
                setHighlightedIndex(index);
                const element = itemRefs.current[index];
                const scrollPosition = element.offsetTop - containerRef.current.offsetTop;
                containerRef.current.scrollTo({top: scrollPosition, behavior: 'smooth'});
                element.classList.remove('bounce-animation');
                void element.offsetWidth;
                element.classList.add('bounce-animation');
            }
        },
    }));

    const handleNameClick = (index) => {
        setEditBoxIndex(index);
        setEditedName(utterances[index].speakerName);
    };

    const handleOptionClick = (name, index, edit_type) => {
        console.log(`Option clicked: ${edit_type} for name: ${name} at index ${index}`);
        handleTranscriptEdit(name, index, edit_type).then(r => closeEditRoutine());
    };

    const handleTranscriptEdit = async (speaker_name, utterance_index, edit_type) => {

        const cleaned_speaker_name = speaker_name.trim()

        try {
            const utterance = utterances[utterance_index];
            const response = await axios.post(`${config.backendUrlApiV1}/transcript/`, {
                speaker_name: cleaned_speaker_name,
                utterance_index: utterance.jsonIndex,
                edit_type: edit_type,
                transcript_id: metaData.transcriptId,
            });

            if (response.status === 200) {
                console.log('Name updated successfully');
                // That call refresh the parent so the edited name appears everywhere
                triggerRefresh()
                // setData({
                //     ...data,
                //     transcript: response.data.transcript,
                // });
            } else {
                console.error('Failed to update name:', response.data);
            }
        } catch (error) {
            console.error('An error occurred while updating the name:', error);
        }
    };

    const handleInputChange = (e) => {
        const cleaned_speaker_name = e.target.value
            // .trim() // Remove leading and trailing whitespace
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .replace(/[^a-zA-Z\s]/g, ''); // Remove any non-alphabetic characters except spaces
        setEditedName(cleaned_speaker_name);
    };

    const handleInputBlur = () => {
        // Short delay to allow for option clicks
        setTimeout(() => {
            if (editBoxIndex !== null) {
                setEditedName(utterances[editBoxIndex].speakerName);
                closeEditRoutine();
            }
        }, 100);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setEditedName(utterances[editBoxIndex].speakerName);
            closeEditRoutine();
        }
    };

    const handleOptionMouseEnter = (event, name) => {
        setHoveredName(name);
        const rect = event.target.getBoundingClientRect();
        setOptionBoxStyle({
            top: `${rect.top}px`,
            left: `${rect.right + 13}px`,
            visibility: 'visible',
            opacity: 1,
        });
    };

    const handleOptionMouseLeavePersistent = () => {
        setOptionBoxStyle((prevStyle) => ({
            ...prevStyle,
            visibility: 'hidden',
            opacity: 0,
        }));
    };

    const closeEditRoutine = useCallback(() => {
        handleOptionMouseLeavePersistent();
        setEditBoxIndex(null);
        setEditedName('');
    }, []);

    useEffect(() => {
        closeEditRoutine();
    }, [closeEditRoutine]);

    if (!data || !utterances || utterances.length === 0) {
        return <Typography variant="body1">No transcription provided</Typography>;
    }

    return (
        <Box className="transcription-container" ref={containerRef}>
            {utterances.map((item, index) => {
                const isCurrentUser = item.currentUser;
                const isHighlighted = index === highlightedIndex;
                return (
                    <Box
                        key={index}
                        id={`transcription-item-id-${index}`}
                        className={`transcription-item ${isCurrentUser ? 'current-user' : 'other-user'} ${isHighlighted ? 'highlighted' : ''}`}
                        ref={(el) => (itemRefs.current[index] = el)}
                    >
                        {!isCurrentUser && (
                            <Box className="speaker-container">
                                {editBoxIndex === index ? (
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={editedName}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                        onKeyDown={handleKeyDown}
                                        className="speaker-edit-input"
                                        autoFocus
                                    />
                                ) : (
                                    <Typography
                                        variant="subtitle1"
                                        className="speaker clickable"
                                        onClick={() => handleNameClick(index)}
                                    >
                                        {item.speakerName}
                                        <EditIcon className="edit-icon"/>
                                    </Typography>
                                )}
                                {editBoxIndex === index && (
                                    <Box className={`speaker-box`}>
                                        {speakerNames.some((speaker) => speaker.toLowerCase().includes(editedName.toLowerCase())) ? (
                                            sortedSpeakerNames.map((speaker, speakerIndex) => (
                                                <Box
                                                    key={speakerIndex}
                                                    className="speaker-box-item"
                                                    onMouseEnter={(e) => handleOptionMouseEnter(e, speaker)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    <Typography variant="body2">{speaker}</Typography>
                                                </Box>
                                            ))
                                        ) : (
                                            <Box
                                                className="speaker-box-item"
                                                onMouseEnter={(e) => handleOptionMouseEnter(e, editedName)}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                <Typography variant="body2">Add '{editedName}' as a new
                                                    speaker</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {editBoxIndex === index && (
                                    <Box
                                        className="option-box"
                                        style={optionBoxStyle}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        <Typography variant="body2" className="option-box-item"
                                                    onClick={() => handleOptionClick(hoveredName, index, 'edit_utterance')}>
                                            Apply to current speaker
                                        </Typography>
                                        <Typography variant="body2" className="option-box-item"
                                                    onClick={() => handleOptionClick(hoveredName, index, 'edit_utterance_all')}>
                                            Apply to all '{item.speakerName}'
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Box
                            // className={`text ${isCurrentUser ? 'current-user' : 'other-user'} ${isHighlighted ? 'highlighted-text' : ''}`}>
                            className={`text other-user ${isHighlighted ? 'highlighted-text' : ''}`}
                            style={!isCurrentUser ? {backgroundColor: getSpeakerColor(item.speakerName)} : {}}
                        >
                            <Typography variant="body2">
                                {item.text}
                            </Typography>
                            <Typography variant="caption" className="time">
                                {item.startTimeFormatted}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
});

export default Transcription;