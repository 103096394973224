// RefreshContext.js
import React, {createContext, useContext, useState, useCallback} from 'react';

const RefreshContext = createContext();

export const RefreshProvider = ({children}) => {
    const [refreshKey, setRefreshKey] = useState(0);

    const triggerRefresh = useCallback(() => {
        setRefreshKey(prevKey => prevKey + 1);
    }, []);

    return (
        <RefreshContext.Provider value={{refreshKey, triggerRefresh}}>
            {children}
        </RefreshContext.Provider>
    );
};

export const useRefresh = () => {
    const context = useContext(RefreshContext);
    if (context === undefined) {
        throw new Error('useRefresh must be used within a RefreshProvider');
    }
    return context;
};