import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import './About.css';

const About = () => {
    return (
        <Box className="about-container">
            <Container maxWidth="md">
                <Typography variant="h2" className="about-title">
                    About Elevaide
                </Typography>
                <Typography variant="h4" className="about-message">
                    This page is under construction
                </Typography>
            </Container>
        </Box>
    );
};

export default About;