// frontend/src/product/Product.js
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import Sidebar from './Sidebar';
import Meeting from './home/Meeting';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Meetings from "./Meetings";
import Upload from "./Upload";

const Product = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{backgroundColor: '#F6F8FC', minHeight: '100vh'}}> {/* Set background color here */}
                <Grid container>
                    <Grid item xs={0.6}>
                        <Sidebar/>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 11.4}>
                        {/*<Box sx={{flexGrow: 1, p: 1, display: 'flex'}}>*/}
                        <Box sx={{flexGrow: 1, p: 1}}>
                            <Routes>
                                <Route path="/" element={<Meetings/>}/>
                                <Route path="home" element={<Meetings/>}/>
                                <Route path="meeting" element={<Meeting/>}/>
                                <Route path="upload" element={<Upload/>}/>
                                {/*<Route path="feedback" element={<FeedbackT/>}/>*/}
                                {/* <Route path="meeting-chat" element={<MeetingChat/>}/> */}
                            </Routes>
                        </Box>
                    </Grid>
                </Grid>
                {/*<Actionbutton/>*/}
            </Box>
        </ThemeProvider>
    );
};

export default Product;
