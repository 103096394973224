import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
} from '@mui/material';
import './Settings.css';

//Todo: This is hardcoded for now, but it should come for the BE

const botJoiningSettings = [
    {
        value: 'all',
        label: 'Join all meetings',
        description: 'The meeting assistant attends all meetings in your calendar',
    },
    {
        value: 'accepted',
        label: 'Join all accepted meetings',
        description: 'The meeting Assistant attends all meetings accepted by you',
    },
    {
        value: 'organized',
        label: 'Join meetings organized by you',
        description: 'The meeting assistant attends all meetings organized by you',
    },
    {
        value: 'invited',
        label: 'Join when invited',
        description: 'Invite bot@elevaide.com to your calendar event, and  the meeting assistant will attend',
    },
];

const Settings = ({open, onClose}) => {
    const [botJoiningSetting, setBotJoiningSetting] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${config.backendUrlApiV1}/settings/`);
                const data = response.data;
                setBotJoiningSetting(data.botJoining);
            } catch (error) {
                console.error('Error fetching settings:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            setLoading(true);
            fetchSettings();
        }
    }, [open]);

    const botJoiningSettingChange = (event) => {
        setBotJoiningSetting(event.target.value);
    };

    const handleSaveSettings = () => {
        try {
            // I had an await here, but it blocked the settings panel view, so I removed it
            axios.post(`${config.backendUrlApiV1}/settings/`, {bot_joining: botJoiningSetting});
            onClose();
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <Box className="modal-box" onClick={(e) => e.stopPropagation()}>
                <Typography variant="h5" className="modal-title">Settings</Typography>
                <Typography variant="h6" className="settings-subtitle">Meeting settings</Typography>
                <Typography variant="body2" className="settings-description">Choose which meetings to join</Typography>
                <RadioGroup value={botJoiningSetting} onChange={botJoiningSettingChange}>
                    {botJoiningSettings.map((option) => (
                        <div key={option.value}>
                            <FormControlLabel value={option.value} control={<Radio/>} label={option.label}/>
                            <Typography variant="body2" className="option-description">
                                {option.description}
                            </Typography>
                        </div>
                    ))}
                </RadioGroup>
                <Button
                    variant="contained"
                    color="primary"
                    className="save-button"
                    onClick={handleSaveSettings}
                    disabled={loading} // Disable button while loading
                >
                    Save changes
                </Button>
            </Box>
        </div>
    );
};

export default Settings;
