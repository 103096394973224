// src/auth/MicrosoftAuth.js
import React from 'react';
import {Button} from '@mui/material';
import {Microsoft} from '@mui/icons-material';
import config from '../config';

const MicrosoftAuth = () => {
    const handleMicrosoftLogin = () => {
        const MICROSOFT_AUTH_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
        const scope = [
            'openid',
            'email',
            'profile',
            'offline_access',
            'User.Read',
            'Calendars.Read'
        ].join(' ');

        const params = {
            client_id: config.microsoftClientId,
            response_type: 'code',
            redirect_uri: config.microsoftRedirectUri,
            scope: scope,
            response_mode: 'query',
            prompt: 'consent'
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location = `${MICROSOFT_AUTH_URL}?${urlParams}`;
    };

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<Microsoft/>}
            onClick={handleMicrosoftLogin}
            fullWidth
            style={{marginTop: '10px'}}
        >
            Sign in with Microsoft
        </Button>
    );
};

export default MicrosoftAuth;